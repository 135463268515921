/*IMPORTANT: Flex as display is important here. It allows us to put the panes beside each other*/
.normal-grid {
    display: flex;
}

/*Set the box to have a min width of 45% of the horizontal screen space. Once we become smaller than the 45% a
scroll bar will appear*/
.box-content {
    min-width: 45vw;
}

/*IMPORTANT: Note that we MUST manually set the size for the gutter. If we do not, it will not display*/
.gutter {
    background-color: grey;
    width: 4px;
    /*height: 100vh;*/
    float: left;
    cursor: col-resize;
}